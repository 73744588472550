import { Box } from "@chakra-ui/react";
import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW_ANY,
} from "@raiden/library/constants/authorizations";
import {
  postsTypePluralMessage,
  POSTS_TYPE_LIST,
} from "@raiden/library/constants/posts";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";
import {
  MdDns,
  MdFeed,
  MdFlag,
  MdHome,
  MdLocalShipping,
  MdPayments,
  MdPerson,
  MdSettings,
} from "react-icons/md";
import { FormattedMessage } from "react-intl";
import uniqid from "uniqid";

/** @type {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} */
const _MENU_ITEMS = [
  {
    title: <FormattedMessage defaultMessage="Accueil" />,
    icon: MdHome,
    href: generateAdminPath({ id: "@admin.dashboard" }),
  },
  {
    title: <FormattedMessage defaultMessage="Paramètres" />,
    icon: MdSettings,
    children: [
      {
        title: <FormattedMessage defaultMessage="Environnements" />,
        href: generateAdminPath({ id: "@admin.environments.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Groupes d'utilisateurs" />,
        href: generateAdminPath({ id: "@admin.usersGroups.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Administrateurs" />,
        href: generateAdminPath({ id: "@admin.usersAdmins.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Système" />,
    icon: MdDns,
    children: [
      {
        title: <FormattedMessage defaultMessage="Laravel Horizon" />,
        href: generateAdminPath({ id: "@admin.internalHorizon.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Laravel Telescope" />,
        href: generateAdminPath({ id: "@admin.internalTelescope.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Statut" />,
        href: generateAdminPath({ id: "@admin.healthStatus.view" }),
        validator: ({ isRoot }) => isRoot,
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Contenus" />,
    icon: MdFeed,
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
      }),
    children: [
      ...POSTS_TYPE_LIST.map((postType) => ({
        title: (
          <Box sx={{ "&::first-letter": { textTransform: "uppercase" } }}>
            <FormattedMessage
              {...postsTypePluralMessage}
              values={{ type: postType.id }}
            />
          </Box>
        ),
        href: generateAdminPath({
          id: "@admin.posts.search",
          parameters: {
            postType: postType.id,
          },
        }),
      })),
      {
        title: <FormattedMessage defaultMessage="Menus" />,
        href: generateAdminPath({ id: "@admin.menus.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Finance" />,
    icon: MdPayments,
    children: [
      {
        title: <FormattedMessage defaultMessage="Factures" />,
        href: generateAdminPath({ id: "@admin.invoices.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Comptabilité" />,
        href: generateAdminPath({ id: "@admin.accountRecords.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Clients" />,
    icon: MdPerson,
    children: [
      {
        title: <FormattedMessage defaultMessage="Base clients" />,
        href: generateAdminPath({ id: "@admin.customers.search" }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@admin.customers.search" }),
            ) &&
            !router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@admin.customers.contacts.search" }),
            )
          );
        },
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Contacts clients" />,
        href: generateAdminPath({ id: "@admin.customers.contacts.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Transporteurs" />,
    icon: MdLocalShipping,
    href: generateAdminPath({ id: "@admin.carriers.search" }),
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
      }),
  },
  {
    title: <FormattedMessage defaultMessage="Pays" />,
    icon: MdFlag,
    href: generateAdminPath({ id: "@admin.countries.search" }),
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
      }),
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} items
 * @param {number} [depth]
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").Item[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);
