// deps
import {
  Box,
  Button,
  DarkMode,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Menu,
  MenuButton,
  Portal,
  Skeleton,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { MdExpandMore, MdMenu } from "react-icons/md";
import { useIntl } from "react-intl";

// components
import EnvironmentSelect from "../../../components/EnvironmentSelectWithoutAuthorizations";
import Logo from "../../../components/Logo";
import UsersAvatar from "../../../components/Users/Avatar";

// containers
import UserMenu from "../../UserMenu";

// hooks
import useAuth from "@raiden/library/hooks/useAuth";

// contexts
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useDefaultEnvironments } from "@raiden/library/contexts/DefaultEnvironments";
import ThemeSwitch from "../ThemeSwitch";

export default function SignedInLayoutHeader(props) {
  const { onClickMenu } = props;

  const { user, loading } = useAuth();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const intl = useIntl();

  const { colorMode, setColorMode } = useColorMode();

  const responsive = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { shouldRenderEnvironments } = usePreferences();

  /**
   * Gère le changement d’environnement par défaut.
   */
  const handleChangeDefaultEnvironment = useCallback(
    function (event) {
      setDefaultEnvironments(event.target.value);
    },
    [setDefaultEnvironments],
  );

  return (
    <DarkMode>
      <Flex
        pos="fixed"
        zIndex="1"
        left="0"
        right="0"
        top="0"
        h="4rem"
        borderBottomColor="gray.600"
        borderBottomWidth="0.0625rem"
        borderBottomStyle="solid"
        alignItems="center"
        px="0.625rem"
        py="0.75rem"
        bgColor="gray.700"
        color="white">
        {responsive ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={"0.3125rem"} w="full">
            <GridItem>
              <HStack
                spacing="0.625rem"
                as="button"
                h="full"
                onClick={onClickMenu}>
                <Icon as={MdMenu} boxSize="1.5rem" />

                <Text>
                  {intl.formatMessage({
                    id: "raiden.admin.containers.SignedInLayout.texts.menu",
                    defaultMessage: "Menu",
                  })}
                </Text>
              </HStack>
            </GridItem>

            <GridItem>
              <Flex h="3rem" justifyContent="center">
                <Logo dimensions={[130, 48]} />
              </Flex>
            </GridItem>
          </Grid>
        ) : (
          <>
            <Box flexGrow={1}>
              <Box h="3rem">
                <Logo dimensions={[130, 48]} />
              </Box>
            </Box>

            <HStack spacing=".5rem">
              <ThemeSwitch colorMode={colorMode} setColorMode={setColorMode} />

              {shouldRenderEnvironments && (
                <EnvironmentSelect
                  width="15.625rem"
                  isMultiple={true}
                  value={defaultEnvironments}
                  onChange={handleChangeDefaultEnvironment}
                />
              )}

              {loading ? (
                <Skeleton w="15.625rem" h="2.5rem" />
              ) : (
                <Menu placement="bottom">
                  <MenuButton
                    as={Button}
                    rightIcon={<MdExpandMore />}
                    variant="ghost"
                    fontWeight="normal">
                    <UsersAvatar size="md" user={user} />
                  </MenuButton>

                  <Portal>
                    <UserMenu />
                  </Portal>
                </Menu>
              )}
            </HStack>
          </>
        )}
      </Flex>
    </DarkMode>
  );
}

SignedInLayoutHeader.propTypes = {
  onClickMenu: PropTypes.func,
};
