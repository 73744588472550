// deps
import PropTypes from "prop-types";
import { Avatar, HStack, Text } from "@chakra-ui/react";

// libraries
import generatePampaUrl from "@raiden/library/libraries/utils/generatePampaUrl";

/**
 * Calcul la taille pour pampa.
 * @param {object} param0
 * @param {object} param0.size
 */
function getSizes({ size }) {
  switch (size) {
    case "xs":
      return { width: 24, height: 24 };

    case "sm":
      return { width: 32, height: 32 };

    case "md":
      return { width: 48, height: 48 };

    case "lg":
      return { width: 64, height: 64 };

    default: {
      return { width: undefined, height: undefined };
    }
  }
}

export default function UsersAvatar(props) {
  const { user, size, shouldRenderDisplayName } = props;

  const { width, height } = getSizes({ size });

  return (
    <HStack>
      <Avatar
        size={size}
        src={
          user?.avatar
            ? generatePampaUrl({
                image: user.avatar,
                format: "default",
                mode: "crop",
                width,
                height,
              })
            : undefined
        }
        name={user?.display_name}
      />

      {shouldRenderDisplayName && <Text>{user?.display_name}</Text>}
    </HStack>
  );
}

UsersAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.any,
  shouldRenderDisplayName: PropTypes.bool,
};

UsersAvatar.defaultProps = {
  shouldRenderDisplayName: true,
};
