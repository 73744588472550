// deps
import {
  Box,
  Button,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

// contexts
import { useGoogleTracking } from "@raiden/library/contexts/GoogleTracking";

export default function CookieBanner(props) {
  const intl = useIntl();

  const { renderBanner, acceptCookies, refuseCookies } = useGoogleTracking();

  return (
    <Box
      display={renderBanner ? "block" : "none"}
      bgColor={useColorModeValue("white", "gray.700")}
      borderColor={useColorModeValue("gray.400", "white")}
      borderStyle="solid"
      borderWidth="0.0625rem"
      borderRadius={"0.3125rem"}
      pos="fixed"
      maxW="37.5rem"
      left="1rem"
      bottom="1rem"
      p="1rem"
      zIndex={1000}>
      <Text fontSize="1.25rem">
        {intl.formatMessage({ defaultMessage: "Gestion des cookies" })}
      </Text>

      <Text mb="1.5rem">
        {intl.formatMessage({
          defaultMessage:
            "Nous utilisons des cookies pour réaliser des statistiques de visites et vous offrir des contenus et services liés à vos centres d’intérêt.",
        })}
      </Text>

      <Wrap spacing="1rem">
        <WrapItem>
          <Button size="sm" variant="outline" onClick={refuseCookies}>
            {intl.formatMessage({
              defaultMessage: "Je refuse l’utilisation des cookies",
            })}
          </Button>
        </WrapItem>

        <WrapItem>
          <Button size="sm" colorScheme="brandPrimary" onClick={acceptCookies}>
            {intl.formatMessage({
              defaultMessage: "J’autorise tous les cookies",
            })}
          </Button>
        </WrapItem>
      </Wrap>
    </Box>
  );
}
