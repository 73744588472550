import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import cookie from "cookie";
import { createContext, memo, useContext, useMemo, useState } from "react";
import { COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS } from "../../constants/cookies";
import { usePreferences } from "../Preferences";

/**
 * @typedef {object} DefaultEnvironmentsContextValue
 * @property {string[]} defaultEnvironments
 * @property {(defaultEnvironment: any) => void} setDefaultEnvironments
 */

export const DefaultEnvironmentsContext = createContext(
  /**
   * @type {DefaultEnvironmentsContextValue}
   **/ ({
    defaultEnvironments: [],
    setDefaultEnvironments: () => {},
  }),
);

const { Provider } = DefaultEnvironmentsContext;

export const DefaultEnvironmentsProvider = memo(
  /**
   * @typedef {Object} Props
   * @property {Record<string, any>} [cookies]
   * @property {React.ReactNode} children
   *
   * @param {Props} props
   */
  function DefaultEnvironmentsProvider({ cookies, children }) {
    const { bestEnvironment } = usePreferences();

    let initialDefaultEnvironments =
      cookies?.[COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS] ?? "";

    initialDefaultEnvironments = initialDefaultEnvironments
      ? initialDefaultEnvironments.split(",")
      : [];

    if (initialDefaultEnvironments.length === 0 && bestEnvironment) {
      initialDefaultEnvironments = [bestEnvironment.id];
    }

    const [defaultEnvironments, setDefaultEnvironments] = useState(
      initialDefaultEnvironments,
    );

    const value = useMemo(() => {
      return {
        defaultEnvironments,
        setDefaultEnvironments: function (defaultEnvironment) {
          setDefaultEnvironments(defaultEnvironment);

          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS,
            defaultEnvironment,
            {
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );
        },
      };
    }, [defaultEnvironments]);

    return <Provider value={value}>{children}</Provider>;
  },
);

export const useDefaultEnvironments = function () {
  return useContext(DefaultEnvironmentsContext);
};
