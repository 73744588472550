import { useCallback } from "react";
import useAuth from "./useAuth";

export default function useCanPerform() {
  const { can } = useAuth();

  const canPerform = useCallback(
    /**
     * @param {object} params
     * @param {string | string[]} [params.authorizations]
     * @param {string[]} [params.resourcePolicies]
     * @param {string | string[]} [params.policies]
     * @param {number} [params.environmentId]
     */
    function ({
      authorizations,
      resourcePolicies = [],
      policies,
      environmentId,
    }) {
      if (
        /* Si le paramètre n’est pas un tableau */
        policies &&
        !Array.isArray(policies)
      ) {
        policies = [policies];
      }

      if (authorizations && !can({ authorizations, environmentId })) {
        return false;
      }

      if (
        Array.isArray(policies) &&
        !policies.every(function (policy) {
          return resourcePolicies.includes(policy);
        })
      ) {
        return false;
      }

      return true;
    },
    [can],
  );

  return canPerform;
}
