// deps
import { forwardRef } from "react";

// components
import EnvironmentSelect from "../EnvironmentSelect";

// context
import { usePreferences } from "@raiden/library/contexts/Preferences";

const EnvironmentSelectWithoutAuthorizations = forwardRef(
  /**
   * @param {Omit<import("../EnvironmentSelect").EnvironmentSelectProps, "environments">} props
   */
  function EnvironmentSelectWithoutAuthorizations(props, ref) {
    const { ...otherProps } = props;

    const { environments } = usePreferences();

    return (
      <EnvironmentSelect
        ref={ref}
        environments={environments}
        {...otherProps}
      />
    );
  },
);

export default EnvironmentSelectWithoutAuthorizations;
