import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle(props) {
    return {
      control: {
        borderColor: mode("gray.700", "gray.400")(props),
      },
    };
  },
  defaultProps: {
    colorScheme: "brandPrimary",
  },
};
