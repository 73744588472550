// deps
import PropTypes from "prop-types";
import { forwardRef } from "react";

// components
import EnvironmentSelect from "../EnvironmentSelect";

// context
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useGuards } from "@raiden/library/contexts/Guards";

// hooks
import useCanPerform from "@raiden/library/hooks/useCanPerform";

/**
 * @param {object} props
 * @return {array}
 */
function getListEnvironmentRelayOnAuthorizations({
  environments,
  authorizations,
  canPerform,
}) {
  if (authorizations) {
    return environments.filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations,
      }),
    );
  }
  return environments;
}

const EnvironmentSelectWithAutorizations = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string | string[]} [authorizations]
   *
   * @param {Omit<import("../EnvironmentSelect").EnvironmentSelectProps, "environments"> & Props} props
   */
  function EnvironmentSelectWithAutorizations(props, ref) {
    const { authorizations, includeWildcard, ...otherProps } = props;

    const { environments } = usePreferences();

    const canPerform = useCanPerform();

    const { environmentListRelyOnAuthorizations, hasWidlcard } = useGuards();

    const localEnvironments = authorizations
      ? getListEnvironmentRelayOnAuthorizations({
          environments,
          authorizations,
          canPerform,
        })
      : environmentListRelyOnAuthorizations;

    if (localEnvironments?.length === 0) {
      return null;
    }

    return (
      <EnvironmentSelect
        ref={ref}
        environments={localEnvironments}
        includeWildcard={includeWildcard && hasWidlcard}
        {...otherProps}
      />
    );
  },
);

EnvironmentSelectWithAutorizations.propTypes = {
  authorizations: PropTypes.string,
  includeWildcard: PropTypes.bool,
};

export default EnvironmentSelectWithAutorizations;
