// deps
// eslint-disable-next-line import/no-unresolved
import generateUrl from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import { adminRouter } from "../../../constants/routers/admin";

// libraries
import encodeBase64 from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeBase64";

/**
 * Retourne le paramètre `t` utile à pampa.
 * Soit `format` soit `transformation` doit être présent afin que cette méthode fonctionne.
 * @param {object} param0
 * @param {object} param0.image
 * @param {string} [param0.format]
 * @param {object} [param0.transformation]
 * @param {number} [param0.width]
 * @param {number} [param0.height]
 * @param {number} [param0.height]
 * @param {"resize" | "crop"} param0.mode
 * @param {"cover" | "contain"} param0.fit
 * @param {boolean} [param0.trim]
 */
function getTransformation({
  image = {},
  format,
  transformation,
  width,
  height,
  mode,
  fit,
  trim,
}) {
  let t = {};

  let tempTransformation;

  if (transformation) {
    tempTransformation = transformation;
  } else {
    const { transformations } = image;

    const transformation = transformations?.[format];

    if (transformation) {
      tempTransformation = transformation;
    }
  }

  t = {
    width,
    height,
    mode,
    fit,
    trim,
    ...(tempTransformation && {
      cropWidth: tempTransformation.width,
      cropHeight: tempTransformation.height,
      x: tempTransformation.x,
      y: tempTransformation.y,
    }),
  };

  const jsonT = JSON.stringify(t);

  if (jsonT.length > 2) {
    return { t: encodeBase64(jsonT) };
  }

  return undefined;
}

/**
 * Retourne l’URI de la route API demandée.
 * @param {object} param0
 * @return {string}
 */
export default function generatePampaUrl({
  image,
  format,
  transformation,
  width,
  height,
  mode,
  fit,
  trim,
  query,
  ...otherProps
}) {
  const transformationQuery = getTransformation({
    transformation,
    image,
    format,
    width,
    height,
    mode,
    fit,
    trim,
  });

  const { pampa_uri: slug } = image;

  return generateUrl({
    router: adminRouter,
    id: "pampa",
    ...otherProps,
    parameters: {
      slug,
    },
    query: {
      ...query,
      ...transformationQuery,
    },
  });
}
