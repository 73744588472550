// helpers
import nextRedirect from "../redirect";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "../../../constants/users";

/**
 * Récupère la layout à afficher ou effectuer une redirection si nécessaire.
 * L’utilisateur sera redirigé vers le bon layout si besoin (à condition qu’il n’y ait pas d’erreur de type rate-limiter).
 * @param {object} param0
 * @param {"signedIn" | "signedOut" | "indifferent"} param0.pageLayout
 * @param {import("http").ServerResponse} [param0.res]
 * @param {boolean} param0.tooManyRequest
 * @param {boolean} param0.logged
 * @param {object} param0.user
 * @param {object} param0.redirections
 * @param {string} param0.redirections.signedIn
 * @param {string} param0.redirections.signedOut
 * @return {Promise<"signedIn" | "signedOut">}
 */
export default async function nextGetLayout({
  pageLayout,
  res,
  tooManyRequest,
  logged,
  user,
  redirections,
}) {
  /** @type {"signedIn" | "signedOut"} */
  let layout = "signedOut";

  if (
    "signedIn" === pageLayout &&
    (!logged || USERS_USER_TYPE_VALUE_ADMIN !== user?.user_type) &&
    !tooManyRequest &&
    res
  ) {
    await nextRedirect({
      code: 302,
      redirectionUri: redirections.signedOut,
      res,
    });
  } else if (
    "signedOut" === pageLayout &&
    logged &&
    USERS_USER_TYPE_VALUE_ADMIN === user?.user_type &&
    !tooManyRequest &&
    res
  ) {
    await nextRedirect({
      code: 302,
      redirectionUri: redirections.signedIn,
      res,
    });
  } else if ("indifferent" === pageLayout) {
    layout = logged ? "signedIn" : "signedOut";
  } else {
    layout = tooManyRequest ? "signedOut" : pageLayout;
  }

  return layout;
}
