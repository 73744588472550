// deps
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// components
import Image from "@raiden/library/components/Image";

// package.json
import pkg from "../../package.json";

// utils
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

export default function Logo(props) {
  const { dimensions, ...otherProps } = props;

  const intl = useIntl();

  const title = intl.formatMessage(
    {
      id: "raiden.admin.components.Logo.texts.logo.alt",
      defaultMessage: "Logo de « {product} »",
    },
    {
      product: pkg.displayName,
    },
  );

  return (
    <Image
      {...otherProps}
      dimensions={dimensions}
      priority={true}
      src={generateAdminPath({
        id: "@admin.internal-assets",
        parameters: { filePath: "logo.png" },
        includeBasePath: true,
      })}
      alt={title}
      title={title}
    />
  );
}

Logo.propTypes = {
  dimensions: PropTypes.arrayOf(PropTypes.number),
};

Logo.defaultProps = {
  dimensions: [520, 192],
};
