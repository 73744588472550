// deps
import { extendTheme } from "@chakra-ui/react";

// constants
import breakpoints from "./breakpoints";
import styles from "./styles";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Input from "./components/input";
import Link from "./components/link";
import Switch from "./components/switch";
import Table from "./components/table";
import Text from "./components/text";
import Textarea from "./components/textarea";
import Tabs from "./components/tabs";
import Modal from "./components/modal";

const theme = extendTheme({
  breakpoints,
  colors: {
    brandPrimary: {
      50: "#E0EFFF",
      100: "#BCD3F5",
      200: "#95B9EA",
      300: "#6DA1DE",
      400: "#458AD3",
      500: "#2C75BA",
      600: "#1F5391",
      700: "#123669",
      800: "#051B42",
      900: "#00061C",
    },
    brandSecondary: {
      50: "#FFF0DD",
      100: "#FADBB4",
      200: "#F4C888",
      300: "#EEB75B",
      400: "#EAB046",
      500: "#EAA82F",
      600: "#D08315",
      700: "#A25B0E",
      800: "#753908",
      900: "#461D00",
    },
    darkGray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
  },
  components: {
    Button,
    Checkbox,
    Input,
    Link,
    Modal,
    Switch,
    Table,
    Text,
    Textarea,
    Tabs,
  },
  styles,
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;
