// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// libraries
import generateApiUrl from "../../../libraries/utils/generateApiUrl";

/**
 * @param {object} params
 * @param {object} params.baseUri
 * @param {string} [params.locale]
 * @param {object} params.cookies
 * @param {object} params.req
 * @param {(string | number)[]} [params.environmentsIds]
 * @param {string[]} [params.fields]
 *
 * @returns {Promise<{
 * configuration?: import("../../../types/Configuration").Configuration;
 * error?: Error;
 * }>}
 */
export default async function nextGetConfiguration({
  baseUri,
  locale,
  cookies,
  req,
  environmentsIds,
  fields,
}) {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      configuration: undefined,
      error: undefined,
    };
  }

  let error;

  const configurationData = await serverApiFetcher(
    generateApiUrl({
      id: "@api.configuration",
      query: {
        fields: fields ?? [
          "globals.covers",
          "globals.files",
          "globals.schooling_levels",
          "globals.payments.methods",
          "globals.global_subscription",
          "environments",
          "records_main_accounts",
          "auth",
          "years",
          "prestations_categories",
        ],
        environment_id: environmentsIds,
      },
    }),
    undefined,
    {
      origin: baseUri.toString(),
      locale,
      cookies,
      req,
    },
  ).catch(function (tempError) {
    console.log("An error occured", tempError);
    error = tempError;
  });

  return {
    configuration: configurationData?.data,
    error,
  };
}
