// polyfills
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

// imports
import { ChakraProvider, cookieStorageManager } from "@chakra-ui/react";
import ErrorGlobal from "@raiden/library/components/Error/Global";
import { ErrorBoundaryDegraded } from "@raiden/library/components/ErrorBoundaryDegraded";
import { DEFAULT_PER_PAGE } from "@raiden/library/constants/api";
import {
  COOKIES_NAME_LIST,
  COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
} from "@raiden/library/constants/cookies";
import { adminBaseUri } from "@raiden/library/constants/routers/admin";
import AuthProvider from "@raiden/library/contexts/Auth";
import { ConfigurationProvider } from "@raiden/library/contexts/Configuration";
import { DefaultEnvironmentsProvider } from "@raiden/library/contexts/DefaultEnvironments";
import { GoogleTrackingProvider } from "@raiden/library/contexts/GoogleTracking";
import { GuardsProvider } from "@raiden/library/contexts/Guards";
import { MaintenanceModeProvider } from "@raiden/library/contexts/MaintenanceMode";
import { PreferencesProvider } from "@raiden/library/contexts/Preferences";
import { TranslationMessagesProvider } from "@raiden/library/contexts/TranslationMessages";
import { apiGetErrorStatus } from "@raiden/library/helpers/api";
import { googleGetTrackingState } from "@raiden/library/helpers/google";
import {
  nextCheckMaintenance,
  nextGetConfiguration,
  nextGetCookies,
  nextGetLayout,
  nextGetUri,
  nextGetUser,
  nextIsAuthorized,
} from "@raiden/library/helpers/next";
import generateAdminUrl from "@raiden/library/libraries/utils/generateAdminUrl";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { I18nProvider } from "@react-aria/i18n";
import { OverlayProvider } from "@react-aria/overlays";
import { SSRProvider } from "@react-aria/ssr";
import cookie from "cookie";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import "focus-visible/dist/focus-visible";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "../components/ErrorBoundary";
import theme from "../constants/theme";
import CookieBanner from "../containers/CookieBanner";
import MaintenanceLayout from "../containers/MaintenanceLayout";
import MaintenanceRequest from "../containers/MaintenanceRequest";
import Progress from "../containers/Progress";
import SignedInLayout from "../containers/SignedInLayout";
import SignedOutLayout from "../containers/SignedOutLayout";
import TooManyRequest from "../containers/TooManyRequest";
import Unauthorized from "../containers/Unauthorized";
import { useMaintenance } from "../hooks/maintenance";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const SWR_CONFIG = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

const CONFIGURATION_FIELDS = [
  "globals.covers",
  "globals.files",
  "environments",
  "records_main_accounts",
  "auth",
];

const CONFIGURATION_URL = generateApiUrl({
  id: "@api.configuration",
  query: {
    fields: CONFIGURATION_FIELDS,
  },
});

/**
 * Retourne le layout à afficher.
 * @param {object} param0
 * @param {"signedIn" | "signedOut" | string} param0.layout
 * @return {any}
 */
function getLayout({ layout }) {
  switch (layout) {
    case "signedOut":
      return SignedOutLayout;

    case "signedIn": {
      return SignedInLayout;
    }

    default:
      return SignedInLayout;
  }
}

/**
 * @typedef {object} Props
 * @property {import("../types/Page").PageInitialProps} [initialProps]
 * @property {Record<string, any>} cookies
 * @property {"signedIn" | "signedOut"} layout
 * @property {boolean} tooManyRequest
 * @property {boolean} maintenanceRequest
 * @property {import("@raiden/library/helpers/next/isAuthorized").NextIsAuthorizedReturn} authorized
 * @property {import("@raiden/library/types/Configuration").Configuration} [configuration]
 * @property {import("@raiden/library/types/User").User} [user]
 * @property {string} [locale]
 * @property {object} initialTranslationMessages
 * @property {any} error
 *
 * @param {Props & { Component: import("react").FC<import("../types/Page").PageInitialProps>}} props
 */
export default function App({
  Component,
  initialProps,
  cookies,
  layout,
  tooManyRequest,
  maintenanceRequest,
  authorized,
  configuration,
  user,
  locale,
  initialTranslationMessages,
  error,
}) {
  const isMaintenance = useMaintenance({ cookies });

  const colorModeManager = cookieStorageManager(
    cookie.serialize("chakra-ui-color-mode", cookies["chakra-ui-color-mode"]),
  );

  const Layout = getLayout({ layout });

  return (
    <ErrorBoundaryDegraded>
      <GoogleTrackingProvider
        initialState={googleGetTrackingState({
          cookieValue: cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES],
        })}
        trackingKey={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_KEY}
        trackingService={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE}>
        <SWRConfig value={SWR_CONFIG}>
          <TranslationMessagesProvider
            initialTranslationMessages={initialTranslationMessages}>
            {({ translationMessages }) => (
              <>
                {locale && (
                  <IntlProvider
                    locale={locale}
                    defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
                    messages={translationMessages}>
                    <I18nProvider locale={locale}>
                      <SSRProvider>
                        <MaintenanceModeProvider>
                          <AuthProvider initialUser={user} isEnabled={true}>
                            <ConfigurationProvider
                              url={CONFIGURATION_URL}
                              initialConfiguration={configuration}>
                              <PreferencesProvider
                                initialPaginationPerPage={
                                  cookies[
                                    COOKIES_NAME_VALUE_PAGINATION_PER_PAGE
                                  ] ?? DEFAULT_PER_PAGE
                                }>
                                <DefaultEnvironmentsProvider cookies={cookies}>
                                  {isMaintenance ? (
                                    <MaintenanceLayout />
                                  ) : (
                                    <ChakraProvider
                                      theme={theme}
                                      colorModeManager={colorModeManager}>
                                      <Layout>
                                        <ErrorBoundary>
                                          <OverlayProvider
                                            style={{ minHeight: "100%" }}>
                                            <GuardsProvider
                                              guard={initialProps?.guard}>
                                              {(() => {
                                                if (tooManyRequest) {
                                                  return <TooManyRequest />;
                                                }
                                                if (maintenanceRequest) {
                                                  return <MaintenanceRequest />;
                                                }
                                                if (error) {
                                                  return (
                                                    <ErrorGlobal
                                                      error={error}
                                                    />
                                                  );
                                                }
                                                if (
                                                  !authorized.locally ||
                                                  !authorized.globally
                                                ) {
                                                  return (
                                                    <Unauthorized
                                                      authorized={authorized}
                                                    />
                                                  );
                                                }
                                                return (
                                                  <>
                                                    <Progress />

                                                    <Component
                                                      {...initialProps}
                                                    />

                                                    <CookieBanner />
                                                  </>
                                                );
                                              })()}
                                            </GuardsProvider>
                                          </OverlayProvider>
                                        </ErrorBoundary>
                                      </Layout>
                                    </ChakraProvider>
                                  )}
                                </DefaultEnvironmentsProvider>
                              </PreferencesProvider>
                            </ConfigurationProvider>
                          </AuthProvider>
                        </MaintenanceModeProvider>
                      </SSRProvider>
                    </I18nProvider>
                  </IntlProvider>
                )}
              </>
            )}
          </TranslationMessagesProvider>
        </SWRConfig>
      </GoogleTrackingProvider>
    </ErrorBoundaryDegraded>
  );
}

/**
 * @param {object} appProps
 * @param {import("next").NextComponentType<import("../types/AppContext").PageContext, import("../types/Page").PageInitialProps>} appProps.Component
 * @param {import("next/dist/shared/lib/utils").AppTreeType} appProps.AppTree
 * @param {import("next").NextPageContext} appProps.ctx
 * @param {import("next/router").default} appProps.router
 */
App.getInitialProps = async function (appProps) {
  const {
    Component,
    router: { locale },
  } = appProps;

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  await nextCheckMaintenance({ res: appProps.ctx.res });

  const { cookies, unSecureCookies } = await nextGetCookies({
    req: appProps.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;

      return cookies;
    }, {}),
  });

  const [configurationData, userData] = await Promise.all([
    nextGetConfiguration({
      cookies,
      baseUri: adminBaseUri,
      locale,
      req: appProps.ctx.req,
      fields: CONFIGURATION_FIELDS,
    }),
    nextGetUser({
      cookies,
      baseUri: adminBaseUri,
      locale,
      req: appProps.ctx.req,
    }),
    intlPolyfills(locale),
  ]);

  const { configuration, error: configurationError } = configurationData;

  const { user, error: userError } = userData;

  const error = configurationError || userError;

  const tooManyRequest =
    429 === apiGetErrorStatus({ error: configurationError }) ||
    429 === apiGetErrorStatus({ error: userError });

  const maintenanceRequest =
    503 === apiGetErrorStatus({ error: configurationError }) ||
    503 === apiGetErrorStatus({ error: userError });

  // only fetch translation messages on the server on initial render
  const initialTranslationMessages =
    typeof window === "undefined" &&
    locale !== undefined &&
    defaultLocale !== undefined
      ? await getTranslationMessages(locale, defaultLocale)
      : null;

  const uri = nextGetUri({
    req: appProps.ctx.req,
    asPath: appProps.ctx.asPath,
  });

  const initialProps = await Component.getInitialProps?.({
    ...appProps.ctx,
    configuration: configuration,
  });

  const layout = await nextGetLayout({
    pageLayout: initialProps?.layout ?? "signedOut",
    tooManyRequest,
    res: appProps.ctx.res,
    logged: Boolean(user),
    user,
    redirections: {
      signedOut: generateAdminUrl({
        id: "@admin.login",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      signedIn: generateAdminUrl({
        id: "@admin.dashboard",
        includeBasePath: true,
      }),
    },
  });

  const authorized = await nextIsAuthorized({
    guard: initialProps?.guard,
    user,
    unSecureCookies,
    withEnvironmentGuard: true,
  });

  /** @type {Props} */
  const values = {
    initialProps,
    cookies: unSecureCookies,
    layout,
    tooManyRequest,
    maintenanceRequest,
    authorized,
    configuration,
    user,
    locale,
    initialTranslationMessages,
    error,
  };

  return values;
};
