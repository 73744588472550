// deps
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  VStack,
  LightMode,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

// components
import Copyright from "../../components/Copyright";
import Logo from "../../components/Logo";
import Image from "@raiden/library/components/Image";
import MaintenanceSplash from "../../components/MaintenanceSplash";

// contanairs
import LocaleSelect from "../LocaleSelect";
import { useIntl } from "react-intl";

// contexts
import { useMaintenanceMode } from "@raiden/library/contexts/MaintenanceMode";

// utils
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

export default function SignedOutLayout(props) {
  const { children } = props;

  const intl = useIntl();

  const { isOpen: maintenanceModeIsOpen, onClose: maintenanceModeOnClose } =
    useMaintenanceMode();

  return (
    <>
      <Modal
        isOpen={maintenanceModeIsOpen}
        size="xl"
        onClose={maintenanceModeOnClose}
        scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            {intl.formatMessage({
              defaultMessage: "Maintenance en cours",
            })}
          </ModalHeader>

          <ModalBody>
            <MaintenanceSplash
              addon={
                <Button onClick={maintenanceModeOnClose}>
                  {intl.formatMessage({ defaultMessage: "Fermer" })}
                </Button>
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <LightMode>
        <Flex>
          <Box
            minW={["100%", null, "30rem"]}
            maxW={["100%", null, "30rem"]}
            h="100vh"
            overflow="auto"
            bgColor={"gray.100"}
            color="black">
            <Flex
              flexDir="column"
              flexGrow={1}
              flexShrink={0}
              py={5}
              px={{ base: "1rem", md: "2.5rem" }}
              h="100%">
              <Box flexGrow={1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  mb="3.125rem"
                  mt="6.25rem"
                  mx="3.125rem">
                  <Logo />
                </Box>

                {children}
              </Box>

              <VStack spacing="1rem" mt="1rem">
                <Copyright />

                <LocaleSelect />

                <Box />
              </VStack>
            </Flex>
          </Box>

          <Box pos="relative" flexGrow={1}>
            <Image
              src={generateAdminPath({
                id: "@admin.internal-assets",
                parameters: { filePath: `templates/signedOut/background.jpg` },
                includeBasePath: true,
              })}
              objectFit="cover"
              layout="fill"
              priority={true}
              alt={intl.formatMessage({ defaultMessage: "Background" })}
            />
          </Box>
        </Flex>
      </LightMode>
    </>
  );
}

SignedOutLayout.propTypes = {
  children: PropTypes.node,
};
