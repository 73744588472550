import usersCan from "../../users/can";

/**
 * Filtre les environnements en fonction d'une authorisation
 * @typedef {object} Props
 * @property {import("../../../types/Environment").Environment[]} environments
 * @property {string} authorizations
 * @property {import("../../../types/User").User} user
 */

/**
 * @param {object} props
 * @return {array}
 */
export default function getEnvironmentListRelyOnAuthorizations({
  environments,
  authorizations,
  user,
}) {
  if (authorizations) {
    return environments.filter((id) =>
      usersCan({
        user,
        environmentId: id,
        authorizations,
      }),
    );
  }
  return environments;
}
